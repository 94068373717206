import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  forgotReducer,
  otpReducer,
  signinReducer,
} from "./reducer/userReducer";
import {
  editRoleReducer,
  getRoleListReducer,
  getRolesReducer,
  addRoleListReducer,
} from "./reducer/roleReducer";
import {
  acceptVendorRequestReducer,
  addadminInvReducer,
  addVendorReducer,
  addvuseradminReducer,
  deleteVendorReducer,
  editVendorReducer,
  editvuseradminReducer,
  getadminvendoruserReducer,
  getappIdReducer,
  getappuserReducer,
  getEmailIdReducer,
  getOrganizationReducer,
  getVendorFilterReducer,
  getvendorlistmodalReducer,
  getVendorReducer,
  getvendoruserlistmodalReducer,
  rejectVendorRequestReducer,
  getVendorRequestReducer,
  registerVendorReducer,
  archivedListReducer,
} from "./reducer/vendorReducer";
import {
  acceptInvReducer,
  acplistApproverReducer,
  addApproverReducer,
  deleteApproverPanelReducer,
  deleteApproverReducer,
  editApproverReducer,
  getApproverApprovedReducer,
  getApproverPaidReducer,
  getApproverPartialPaidReducer,
  getApproverPendingReducer,
  getApproverReducer,
  getApproverRejectedReducer,
  getApproverUpcomingOverdueReducer,
  getvenIdReducer,
  getvenuserReducer,
  invApproverLengthReducer,
  invApproverReducer,
  rejectInvReducer,
  rejlistApproverReducer,
} from "./reducer/approverReducer";
import {
  addFinancerReducer,
  allInvFinanceReducer,
  deleteFinancerReducer,
  editFinancerReducer,
  getFinancerReducer,
  getOverviewReducer,
  getUpcomingOverdueFinancerReducer,
  overdueFinanceReducer,
  paidInvFinanceReducer,
  partialInvFinanceReducer,
  paidStatusReducer,
  rejectStatusReducer,
  rejInvFinanceReducer,
  getfinancerPaymentPendingReducer,
  getfinancerPaidReducer,
  getfinancerPartialPaymentReducer,
  getfinancerRejectedReducer,
} from "./reducer/financerReducer";
import {
  addUserReducer,
  deleteUserReducer,
  editUserReducer,
  getAdminReducer,
  getAdminUpcomingOverdueReducer,
  getDepartmentReducer,
  getFilterReducer,
  getUserReducer,
  addAdminReducer,
  editAdminReducer,
  deleteAdminReducer,
  getInvoiceDetailsListReducer,
} from "./reducer/adminUserReducer";
import {
  getVendorprofileReducer,
  getVendorsReducer,
  getViewInvReducer,
  getTotalInvoiceReducer,
  getAmountVendorStatusReducer,
} from "./reducer/vendorReducer/vendorReducer";
import {
  addVendoruserReducer,
  deleteVendoruserReducer,
  editVendoruserReducer,
  getInvoiceByUserReducer,
  getVendoruserReducer,
} from "./reducer/vendorReducer/usermngReducer";
import {
  addAcceptinvReducer,
  addRejectinvReducer,
  getApproverFilterReducer,
  getApproverInvoicelistReducer,
  getApproverStatusReducer,
  getVendorlistReducer,
  getapproverAmountStatusReducer,
  getApproverInvoiceLenReducer,
  getApproverVendorRequestReducer,
} from "./reducer/approver/approverReducer";
import {
  addRejectfinancerReducer,
  archivelistReducer,
  archiveReducer,
  getFinancerorgReducer,
  getOverdueReducer,
  getPaidInvoiceReducer,
  unarchiveReducer,
  updateStatusReducer,
  selectedInvoices,
  formikData,
} from "./reducer/financers/financerReducer";
import {
  addFileReducer,
  createInvoiceReducer,
  deleteInvoivceMngtReducer,
  editInvoiceReducer,
  getDataReducer,
  getFilterInvReducer,
  getGstTdsReducer,
  getInvoivceMngtReducer,
  getupdateInvDetailsReducer,
  getViewpdfReducer,
  profileFileReducer,
  updateFileReducer,
} from "./reducer/vendorReducer/invoiceReducer";
import {
  adminacceptReducer,
  adminpaidReducer,
  adminrejectReducer,
  getAdminFinaceAmount,
  getAdminFinacestatus,
  getadminListReducer,
  getApproverCountAdmin,
  getCountAdmin,
  getDepartment,
  getFinancerCountAdmin,
  getOrganization,
  getPaidCountAdmin,
  getPendingPayAdmin,
  getRejAdmin,
  getReviewPenAdmin,
  getVendorCountAdmin,
} from "./reducer/admin/dashboardReducer";
import { addPermissionReducer } from "./reducer/admin/manuserReducer";

const finalReducer = combineReducers({
  profile: profileFileReducer,
  // Admin Reducers
  signin: signinReducer,
  getrolelist: getRoleListReducer,
  getrole: getRolesReducer,
  addrole: addRoleListReducer,
  editRole: editRoleReducer,
  adminOverdue: getAdminUpcomingOverdueReducer,
  invoiceDetailsList: getInvoiceDetailsListReducer,

  //Admin Crud Reducers
  getadmin: getAdminReducer,
  addadmin: addAdminReducer,
  editadmin: editAdminReducer,
  deladmin: deleteAdminReducer,

  addVendor: addVendorReducer,
  editVendor: editVendorReducer,
  delVendor: deleteVendorReducer,
  getvendor: getVendorReducer,
  getorg: getOrganizationReducer,
  getvendorfilter: getVendorFilterReducer,
  getgsttds: getGstTdsReducer,

  getapprover: getApproverReducer,
  addapprover: addApproverReducer,
  editapprover: editApproverReducer,
  delapprover: deleteApproverReducer,
  delete: deleteApproverPanelReducer,

  getfinancer: getFinancerReducer,
  addfinancer: addFinancerReducer,
  editfinancer: editFinancerReducer,
  delfinancer: deleteFinancerReducer,

  userAdd: addUserReducer,
  editUser: editUserReducer,
  getuser: getUserReducer,

  getdept: getDepartmentReducer,
  getfilter: getFilterReducer,

  org: getOrganization,
  dept: getDepartment,
  vendoruser: getadminvendoruserReducer,
  getvid: getvenIdReducer,
  getvlist: getvendorlistmodalReducer,
  getvuserlist: getvendoruserlistmodalReducer,

  getappInv: invApproverReducer,
  getapplen: invApproverLengthReducer,
  getacplist: acplistApproverReducer,
  getrejlist: rejlistApproverReducer,
  acpInv: acceptInvReducer,
  rejInv: rejectInvReducer,

  getallinv: allInvFinanceReducer,
  getpaidinv: paidInvFinanceReducer,
  getpartialinv: partialInvFinanceReducer,
  getrejinv: rejInvFinanceReducer,
  overdueFinance: overdueFinanceReducer,
  paidInv: paidStatusReducer,
  rejectInv: rejectStatusReducer,

  getauser: getappuserReducer,
  getvuser: getvenuserReducer,
  getaid: getappIdReducer,

  addvuser: addvuseradminReducer,
  editvuser: editvuseradminReducer,
  vdeluser: deleteUserReducer,

  addadmininv: addadminInvReducer,

  countOne: getCountAdmin,
  paendingPayment: getPendingPayAdmin,
  reviewOne: getReviewPenAdmin,
  rejectOne: getRejAdmin,
  paidc: getPaidCountAdmin,

  venCount: getVendorCountAdmin,
  appCount: getApproverCountAdmin,
  finCount: getFinancerCountAdmin,

  emails: getEmailIdReducer,
  venRequest: getVendorRequestReducer,
  accRequest: acceptVendorRequestReducer,
  rejRequst: rejectVendorRequestReducer,

  archived: archivedListReducer,

  //Admin financer Status
  statusaf: getAdminFinacestatus,
  amountaf: getAdminFinaceAmount,

  //Vendor Reducer
  addinvoice: createInvoiceReducer,
  editinv: editInvoiceReducer,
  getvendors: getVendorsReducer,
  addvendoruser: addVendoruserReducer,
  updateUser: editVendoruserReducer,
  deluser: deleteVendoruserReducer,
  getvendoruser: getVendoruserReducer,
  getinvoiceuser: getDataReducer,
  getinvoices: getInvoivceMngtReducer,
  delinv: deleteInvoivceMngtReducer,
  addvendor: addVendoruserReducer,
  getprofile: getVendorprofileReducer,
  getProfilePerUser: getVendorprofileReducer,
  getfilterinv: getFilterInvReducer,
  addfileinv: addFileReducer,
  updateFile: updateFileReducer,
  getviewinv: getViewInvReducer,
  getpdf: getViewpdfReducer,
  updateStatus: updateStatusReducer,
  gettotal: getTotalInvoiceReducer,
  getAmtStatus: getAmountVendorStatusReducer,
  updateInfo: getupdateInvDetailsReducer,

  //vendoruser
  getInvoice: getInvoiceByUserReducer,

  //Approver Reducer
  getvendorlist: getVendorlistReducer,
  getinvoicelist: getApproverInvoicelistReducer,
  appInvlen: getApproverInvoiceLenReducer,
  getfilters: getApproverFilterReducer,
  acceptMsg: addAcceptinvReducer,
  rejectMsg: addRejectinvReducer,
  getListStatus: getApproverStatusReducer,
  getapproverAmt: getapproverAmountStatusReducer,
  getapprVenReq: getApproverVendorRequestReducer,
  getUpOverdue: getApproverUpcomingOverdueReducer,
  getApproverPending: getApproverPendingReducer,
  getApproverPaid: getApproverPaidReducer,
  getApproverPartialPaid: getApproverPartialPaidReducer,
  getApproverApproved: getApproverApprovedReducer,
  getApproverRejected: getApproverRejectedReducer,
  getApproverVendorRequestReducer: getApproverVendorRequestReducer,

  //Financer Reducer
  getfinancerorg: getFinancerorgReducer,
  getoverdue: getOverdueReducer,
  getstatus: updateStatusReducer,
  getPaid: getPaidInvoiceReducer,
  getReject: addRejectfinancerReducer,
  addforgot: forgotReducer,
  otpReducer: otpReducer,
  getOverview: getOverviewReducer,
  getUpcomingOverdue: getUpcomingOverdueFinancerReducer,

  getarchive: archivelistReducer,
  archive: archiveReducer,
  unarchive: unarchiveReducer,

  addpermission: addPermissionReducer,

  vendorForm: registerVendorReducer,

  getfinancerPaymentPending: getfinancerPaymentPendingReducer,
  getfinancerPaid: getfinancerPaidReducer,
  getfinancerPartialPayment: getfinancerPartialPaymentReducer,
  getfinancerRejected: getfinancerRejectedReducer,

  selectedInvoices: selectedInvoices,
  formikData: formikData,
  //admin tab
  admin: getAdminReducer,
  getlist: getadminListReducer,
  accept: adminacceptReducer,
  paid: adminpaidReducer,
  reject: adminrejectReducer,
});

const store = createStore(
  finalReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
