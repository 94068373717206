export const ADD_VENDOR_REQUEST = "ADD_VENDOR_REQUEST"
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS"
export const ADD_VENDOR_FAILED  = "ADD_VENDOR_FAILED"

export const GET_VENDOR_REQUEST = "GET_VENDOR_REQUEST"
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS"
export const GET_VENDOR_FAILED = "GET_VENDOR_FAILED"


export const EDIT_VENDOR_REQUEST = "EDIT_VENDOR_REQUEST"
export const EDIT_VENDOR_SUCCESS = "EDIT_VENDOR_SUCCESS"
export const EDIT_VENDOR_FAILED = "EDIT_VENDOR_FAILED"

export const DELETE_VENDOR_REQUEST = "DELETE_VENDOR_REQUEST"
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS"
export const DELETE_VENDOR_FAILED = "DELETE_VENDOR_FAILED"

//Organization
export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST"
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS"
export const GET_ORGANIZATION_FAILED = "GET_ORGANIZATION_FAILED"

// Vendor Filter
export const GET_VENDORFILTER_REQUEST = "GET_VENDORFILTER_REQUEST"
export const GET_VENDORFILTER_SUCCESS = "GET_VENDORFILTER_SUCCESS"
export const GET_VENDORFILTER_FAILED = "GET_VENDORFILTER_FAILED"

//search
export const GET_SEARCHFIELD_REQUEST = "GET_SEARCHFIELD_REQUEST"
export const GET_SEARCHFIELD_SUCCESS = "GET_SEARCHFIELD_SUCCESS"
export const GET_SEARCHFIELD_FAILED = "GET_SEARCHFIELD_FAILED"

//View vendor user
export const GET_ADMINVUSER_REQUEST = "GET_ADMINVUSER_REQUEST"
export const GET_ADMINVUSER_SUCCESS = "GET_ADMINVUSER_SUCCESS"
export const GET_ADMINVUSER_FAILED = "GET_ADMINVUSER_FAILED"

//vendor modal list
export const GET_VENDORMLIST_REQUEST = "GET_VENDORMLIST_REQUEST"
export const GET_VENDORMLIST_SUCCESS = "GET_VENDORMLIST_SUCCESS"
export const GET_VENDORMLIST_FAILED = "GET_VENDORMLIST_FAILED"

//vendor user modal list
export const GET_VENDORUMLIST_REQUEST = "GET_VENDORUMLIST_REQUEST"
export const GET_VENDORUMLIST_SUCCESS = "GET_VENDORUMLIST_SUCCESS"
export const GET_VENDORUMLIST_FAILED = "GET_VENDORUMLIST_FAILED"

//Admin vendor user modal add and update
export const ADD_ADMINVUSER_REQUEST = "ADD_ADMINVUSER_REQUEST"
export const ADD_ADMINVUSER_SUCCESS = "ADD_ADMINVUSER_SUCCESS"
export const ADD_ADMINVUSER_FAILED = "ADD_ADMINVUSER_FAILED"

export const EDIT_ADMINVUSER_REQUEST = "EDIT_ADMINVUSER_REQUEST"
export const EDIT_ADMINVUSER_SUCCESS = "EDIT_ADMINVUSER_SUCCESS"
export const EDIT_ADMINVUSER_FAILED = "EDIT_ADMINVUSER_FAILED"

//Get approver user list in add vendor modal
export const GET_APPUSER_REQUEST = "GET_APPUSER_REQUEST"
export const GET_APPUSER_SUCCESS = "GET_APPUSER_SUCCESS"
export const GET_APPUSER_FAILED = "GET_APPUSER_FAILED"


export const GET_APPID_REQUEST = "GET_APPID_REQUEST"
export const GET_APPID_SUCCESS = "GET_APPID_SUCCESS"
export const GET_APPID_FAILED = "GET_APPID_FAILED"

export const CREATE_ADDINV_REQUEST = "CREATE_ADDINV_REQUEST"
export const CREATE_ADDINV_SUCCESS = "CREATE_ADDINV_SUCCESS"
export const CREATE_ADDINV_FAILED = "CREATE_ADDINV_FAILED"

export const GET_EMAILIDS_REQUEST = "GET_EMAILIDS_REQUEST"
export const GET_EMAILIDS_SUCCESS = "GET_EMAILIDS_SUCCESS"
export const GET_EMAILIDS_FAILED = "GET_EMAILIDS_FAILED"

//vendor register form
export const REG_VENDOR_REQUEST = "REG_VENDOR_REQUEST"
export const REG_VENDOR_SUCCESS = "REG_VENDOR_SUCCESS"
export const REG_VENDOR_FAILED = "REG_VENDOR_FAILED"

export const GET_VENREQUEST_REQUEST = "GET_VENREQUEST_REQUEST"
export const GET_VENREQUEST_SUCCESS = "GET_VENREQUEST_SUCCESS"
export const GET_VENREQUEST_FAILED = "GET_VENREQUEST_FAILED"

export const ACCEPT_VENDOR_REQUEST = "ACCEPT_VENDOR_REQUEST"
export const ACCEPT_VENDOR_SUCCESS = "ACCEPT_VENDOR_SUCCESS"
export const ACCEPT_VENDOR_FAILED = "ACCEPT_VENDOR_FAILED"

export const REJECT_VENDOR_REQUEST = "REJECT_VENDOR_REQUEST"
export const REJECT_VENDOR_SUCCESS = "REJECT_VENDOR_SUCCESS"
export const REJECT_VENDOR_FAILED = "REJECT_VENDOR_FAILED"

export const ARCHIVED_REQUEST = "ARCHIVED_REQUEST"
export const ARCHIVED_SUCCESS = "ARCHIVED_SUCCESS"
export const ARCHIVED_FAILED = "ARCHIVED_FAILED"

