import { toast } from "react-toastify";
import * as venType from "../constant/vendorConstant/vendorConstants";
import * as userType from "../constant/vendorConstant/usermanagement";
import * as invType from "../constant/vendorConstant/invoicemng";
import {
  ADD_IMG,
  CREATE_INV,
  DEL_INV,
  GST,
  INV_DATA,
  PROFILE,
  TDS,
  UPDATEINV,
  VENUSER_LIST,
  VEN_DASH,
  VEN_VUSER,
  VUSER_INV,
} from "../resources/APIEndpoints";
import {
  delservice,
  getservice,
  postservice,
  putservice,
} from "../api/apiservices/service";
import { CapitaliseString } from "../utility";

//Vendor Dashboard
export const getVendorsAction =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: venType.GET_VENDORS_REQUEST });
    try {
      const response = await getservice(
        VEN_DASH +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({ type: venType.GET_VENDORS_SUCCESS, payload: response.data });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: venType.GET_VENDORS_FAILED, payload: error });
    }
  };

//Add Vendor User
export const addVendoruserAction = (vendoruserdet) => async (dispatch) => {
  dispatch({ type: userType.ADD_VENDORUSER_REQUEST });
  try {
    const response = await postservice(VEN_VUSER + "/create", vendoruserdet);
    dispatch({ type: userType.ADD_VENDORUSER_SUCCESS, payload: response.data });
    let str = response.data.message + "d Successfully";
    let newStr = CapitaliseString(str);
    toast.success(newStr);

    // close()
  } catch (error) {
    dispatch({ type: userType.ADD_VENDORUSER_FAILED, payload: error });
  }
};

//Get Vendor User
export const getVendorusersAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: userType.GET_VENDORUSER_REQUEST });
    try {
      const response = await getservice(
        VENUSER_LIST + `?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: userType.GET_VENDORUSER_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: userType.GET_VENDORUSER_FAILED, payload: error });
    }
  };

//Update Vendor User
export const editVendorusersAction = (id, userinfo) => async (dispatch) => {
  dispatch({ type: userType.EDIT_VENDORUSER_REQUEST });
  try {
    const response = await putservice(VEN_VUSER + `/update/${id}`, userinfo);
    dispatch({
      type: userType.EDIT_VENDORUSER_SUCCESS,
      payload: response.data,
    });

    toast.success("User Updated Successfully");
  } catch (error) {
    dispatch({ type: userType.EDIT_VENDORUSER_FAILED, payload: error });
  }
};

//delete vendoruser
export const deleteVendorusersAction = (id, close) => async (dispatch) => {
  dispatch({ type: userType.DELETE_VENDORUSER_REQUEST });
  try {
    const response = await delservice(VEN_VUSER + `/delete/${id}`);
    dispatch({
      type: userType.DELETE_VENDORUSER_SUCCESS,
      payload: response.data,
    });

    let newStr = CapitaliseString(response.data.message);
    toast.success(newStr);

    close();
  } catch (error) {
    dispatch({ type: userType.DELETE_VENDORUSER_FAILED, payload: error });
  }
};

// //Get Vendor details
// export const getVendordetailsAction = () => async (dispatch) => {
//     dispatch({ type: invType.GET_INVOICEDATA_REQUEST })
//     try {
//         const response = await getservice(PROFILE)
//         dispatch({ type: invType.GET_INVOICEDATA_SUCCESS, payload: response.data })
//         toast.success(response.data.message)
//     }
//     catch (error) {
//         dispatch({ type: invType.GET_INVOICEDATA_FAILED, payload: error })
//     }
// }

//Create Invoice
export const createinvoiceAction =
  (invoicedetails, history, title) => async (dispatch) => {
    dispatch({ type: invType.CREATE_INVOICE_REQUEST });
    try {
      const response = await postservice(CREATE_INV, invoicedetails);
      dispatch({
        type: invType.CREATE_INVOICE_SUCCESS,
        payload: response.data,
      });
      if (title === "user") {
        history.push("/dashboard");
      } else {
        history.push("/vendor/invoice-management");
      }
      toast.success(response.data.message + " Successfully");
    } catch (error) {
      dispatch({ type: invType.CREATE_INVOICE_FAILED, payload: error });
    }
  };

//Edit invoice
export const editInvoiceAction =
  (invData, history, title, id = "0") =>
  async (dispatch) => {
    dispatch({ type: invType.EDIT_INVOICE_REQUEST });
    try {
      const response = await putservice(
        CREATE_INV + `/${invData.invoiceno}`,
        invData
      );
      dispatch({ type: invType.EDIT_INVOICE_SUCCESS, payload: response.data });
      if (title === "user") {
        history.push("/dashboard");
      } else if (title === "admin") {
        history.push(`/admin/vendor-list/${id}`);
      } else {
        history.push("/vendor/invoice-management");
      }
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: invType.EDIT_INVOICE_FAILED, payload: error });
    }
  };

//invoice management
export const getInvoicedataAction =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: invType.GET_INVOICEMNG_REQUEST });
    try {
      const response = await getservice(
        INV_DATA +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({
        type: invType.GET_INVOICEMNG_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: invType.GET_INVOICEMNG_FAILED, payload: error });
    }
  };

//Get vendor user invoice
export const getInvoiceByUserAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: userType.GET_INVBYUSER_REQUEST });
    try {
      const response = await getservice(
        VUSER_INV + `?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: userType.GET_INVBYUSER_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: userType.GET_INVBYUSER_FAILED, payload: error });
    }
  };

//Delete invoice
export const deleteInvoiceAction = (invoiceno, close) => async (dispatch) => {
  dispatch({ type: invType.DELETE_INVOICEMNG_REQUEST });
  try {
    const response = await delservice(DEL_INV + `/${invoiceno}`);
    dispatch({
      type: invType.DELETE_INVOICEMNG_SUCCESS,
      payload: response.data,
    });
    let newStr = CapitaliseString(response.data.message);
    toast.success(newStr);
    close();
  } catch (error) {
    dispatch({ type: invType.DELETE_INVOICEMNG_FAILED, payload: error });
  }
};

//Add File Create Invoice
export const addFileAction = (data, name) => async (dispatch) => {
  dispatch({ type: invType.ADD_FILE_REQUEST });
  try {
    const response = await postservice(ADD_IMG + `?reason=${name}`, data);
    toast.success(response.data.message)
    const paths = response.data.data.destination.split("/")[2];
    if (paths === "invoice") {
      dispatch({
        type: "INVOICE_INV",
        payload: response.data.data.destination,
      });
    }
    if (paths === "documents") {
      dispatch({ type: "DOCS", payload: response.data.data.destination });
    }
  } catch (error) {
    dispatch({ type: invType.ADD_FILE_FAILED, payload: error });
    toast.error(error.response.data.message)
  }
};

//Add File Create Invoice
export const updateFileAction =
  (data, name, files, index) => async (dispatch) => {
    dispatch({ type: invType.UPDATE_FILE_REQUEST });
    try {
      const response = await postservice(ADD_IMG + `?reason=${name}`, data);
      const paths = response.data.data.destination.split("/")[2];
      if (paths === "invoice") {
        dispatch({
          type: "UPDATE_INVOICE",
          payload: response.data.data.destination,
        });
      }
      if (paths === "documents") {
        var arr = files;
        arr[index] = response.data.data.destination;
        dispatch({ type: "UPDATE_DOCS", payload: arr });
      }
    } catch (error) {
      dispatch({ type: invType.UPDATE_FILE_FAILED, payload: error });
    }
  };

export const profileFileAction = (data, name) => async (dispatch) => {
  dispatch({ type: invType.PROFILE_FILE_REQUEST });
  try {
    const response = await postservice(ADD_IMG + `?reason=${name}`, data);
    const paths = response.data.data.destination.split("/")[2];

    if (paths === "gst") {
      dispatch({ type: "GST_FILE", payload: response.data.data.destination });
    }
    if (paths === "pancard") {
      dispatch({ type: "PAN_FILE", payload: response.data.data.destination });
    }
  } catch (error) {
    dispatch({ type: invType.PROFILE_FILE_FAILED, payload: error });
  }
};

//Get gst list and tds list
export const getGstTdsListAction = () => async (dispatch) => {
  dispatch({ type: invType.GST_TDS_REQUEST });
  try {
    const response = await getservice(GST);
    const data = await getservice(TDS);

    dispatch({
      type: invType.GST_TDS_SUCCESS,
      payload: {
        gst: response.data,
        tds: data.data,
      },
    });
  } catch (error) {
    dispatch({ type: invType.GST_TDS_FAILED, payload: error });
  }
};

//get details update invoice
export const getupdateInvAction = (invoice) => async (dispatch) => {
  dispatch({ type: invType.INVDETAILS_REQUEST });
  try {
    const response = await getservice(UPDATEINV + `/${invoice}`);
    dispatch({ type: invType.INVDETAILS_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: invType.INVDETAILS_FAILED, payload: error });
  }
};

//Get filter By Amount
export const getFilterInvAction = (amtfrom, amtto) => async (dispatch) => {
  dispatch({ type: invType.GET_INVFILTER_REQUEST });
  try {
    const response = await getservice(
      INV_DATA + `?fromamount=${amtfrom}&toamount=${amtto}`
    );
    dispatch({ type: invType.GET_INVOICEMNG_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: invType.GET_INVFILTER_FAILED, payload: error });
  }
};
