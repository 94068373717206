import * as venType from "../constant/adminConstant/vendorConstant";

export const addVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.ADD_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.ADD_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vendorInfo: action.payload,
      };
    case venType.ADD_VENDOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const editVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.EDIT_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.EDIT_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vendorEdit: action.payload,
      };
    case venType.EDIT_VENDOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vendor: action.payload,
      };
    case venType.GET_VENDOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.DELETE_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.DELETE_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        deleteInfo: action.payload,
      };
    case venType.DELETE_VENDOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getOrganizationReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        organization: action.payload,
      };
    case venType.GET_ORGANIZATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getVendorFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VENDORFILTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VENDORFILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vendorfilter: action.payload,
      };
    case venType.GET_VENDORFILTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//View vendor user reducer
export const getadminvendoruserReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_ADMINVUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_ADMINVUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        adminvuser: action.payload,
      };
    case venType.GET_ADMINVUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get vendor modal list
export const getvendorlistmodalReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VENDORMLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VENDORMLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vmodalList: action.payload,
      };
    case venType.GET_VENDORMLIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get vendor user odal list
export const getvendoruserlistmodalReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VENDORUMLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VENDORUMLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vuserlist: action.payload,
      };
    case venType.GET_VENDORUMLIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Add vendor user admin side
export const addvuseradminReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.ADD_ADMINVUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.ADD_ADMINVUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        addavuser: action.payload,
      };
    case venType.ADD_ADMINVUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const editvuseradminReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.EDIT_ADMINVUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.EDIT_ADMINVUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        editavuser: action.payload,
      };
    case venType.EDIT_ADMINVUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getappuserReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_APPUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_APPUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        appuserlist: action.payload,
      };
    case venType.GET_APPUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getappIdReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_APPID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_APPID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        appid: action.payload,
      };
    case venType.GET_APPID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const addadminInvReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.CREATE_ADDINV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.CREATE_ADDINV_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        addInv: action.payload,
      };
    case venType.CREATE_ADDINV_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getEmailIdReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_EMAILIDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_EMAILIDS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        emailids: action.payload,
      };
    case venType.GET_EMAILIDS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//vendor register form
export const registerVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.REG_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.REG_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        regVedor: action.payload,
      };
    case venType.REG_VENDOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getVendorRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VENREQUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VENREQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        getRequest: action.payload,
      };
    case venType.GET_VENREQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const acceptVendorRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.ACCEPT_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.ACCEPT_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        acceptReq: action.payload,
      };
    case venType.ACCEPT_VENDOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const rejectVendorRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.REJECT_VENDOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.REJECT_VENDOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        rejectReq: action.payload,
      };
    case venType.REJECT_VENDOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const archivedListReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.ARCHIVED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.ARCHIVED_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        adminArchived: action.payload,
      };
    case venType.ARCHIVED_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
