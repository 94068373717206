export const setCookie = (name, value, days) => {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
export const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const addCommasToNumber = (number = "") => {
  let convert = number;
  const splitConvert = convert.split(".");
  splitConvert[0] =
    splitConvert[0] !== ""
      ? splitConvert[0]
          .replace(/\D/g, "")
          .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",")
      : "";
  return splitConvert.join(".");
};

export const removeCommasConvertToNumber = (number = "") => {
  if (typeof number === "string") {
    return +number.split(",").join("");
  }
  return number;
};

export const CapitaliseString = (str) => {
  return str
    .split(" ")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const getTodaysDate = () => {
  let endDate = new Date();

  let date1 = endDate.getDate();
  let month1 = endDate.getMonth() + 1;
  let year1 = endDate.getUTCFullYear();

  if (date1 < 10) {
    date1 = "0" + date1;
  }
  if (month1 < 10) {
    month1 = "0" + month1;
  }

  let string1 = year1 + "-" + month1 + "-" + date1;
  return string1;
};

export const getMinDateForEndDate = (viewData) => {
  let splittedStartDate = viewData.startDate.split("-");

  let year = Number(splittedStartDate[0]);
  let month = Number(splittedStartDate[1]);
  let date = Number(splittedStartDate[2]);

  if (date < 10) {
    date = "0" + date;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return year + "-" + month + "-" + date;
};

export const getMaxDateForStartDate = (viewData) => {
  let splittedEndDate = viewData.endDate.split("-");
  let year = Number(splittedEndDate[0]);
  let month = Number(splittedEndDate[1]);
  let date = Number(splittedEndDate[2]);

  if (date < 10) {
    date = "0" + date;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return year + "-" + month + "-" + date;
};
