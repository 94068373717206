import React, { useState } from "react";
import "./vendorDashboard.css";
import { Field, Formik, Form } from "formik";
import ReCaptchaV2 from "react-google-recaptcha";
import * as Yup from "yup";
import "./vendorDashboard.css";
import { profileFileAction } from "../../action/vendorAction";
import { vendorRegisterAction } from "../../action/adminAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

function VendorRegisterForm() {
  const dispatch = useDispatch();
  const { vendorprofile } = useSelector((state) => state.getprofile);
  const { gst, pan } = useSelector((state) => state.profile);
  const [onCaptchaCheck, setOnCaptchaCheck] = useState(false);
  const [gstfile, setGstfile] = useState("Upload Gst Certificate");
  const [panfile, setPanfile] = useState("Upload PAN Card");
  const initialvalue = {
    organization: "",
    name: "",
    email: "",
    mobileNumber: "",
    panNumber: "",
    gstNumber: "",
    tan_num: "",
    pan_file: "",
    gst_file: "",
    bankAccountNumber: "",
    ifscCode: "",
  };
  const phoneRegEx = /^[6-9][0-9]{9}$/;
  const ifcsRegEx = /^[A-Z]{4}0[A-Z0-9]{6}$/; /// regex have to change
  const bankNoRegEx = /^[A-Za-z0-9]{9,18}$/; /// regex have to change
  const tan_num = /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/;
  const vendorSchema = Yup.object().shape({
    organization: Yup.string()
      .required("Organization is required")
      .matches(/^[a-zA-Z]/i, "Only characters allowed"),
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .matches(/^[a-zA-Z]+ [a-zA-Z]+$/i, "Enter first name and last name")
      .required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobileNumber: Yup.string()
      .max(10, "too long")
      .matches(phoneRegEx, "Invalid mobile number")
      .required("Mobile number is required"),
    bankAccountNumber: Yup.string()
      .min(8, "Too Short!")
      .max(19, "too long")
      .matches(bankNoRegEx, "Invalid Account number")
      .required("Account number is required"),
    ifscCode: Yup.string()
      .max(11, "Maximum 11 characters allowed")
      .matches(ifcsRegEx, "Invalid IFSC code")
      .required("IFSC Code is required"),
    panNumber: Yup.string()
      .matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/i, "Invalid PAN Number")
      .required("Pan Number is required"),
    // tan_num: Yup.string()
    // .matches(tan_num, "Invalid TAN Number"),
    // .required("Tan Number is required"),
    gstNumber: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/i,
      "Invalid GST Number"
    ),
    //   .required("Gst Number is required"),
    // gst_file: Yup.mixed()
    //   .required("GST document is required")
    //   .test("fileFormat", "Unsupported file format", (value) => {
    //     return (
    //       value &&
    //       [
    //         "image/jpg",
    //         "image/jpeg",
    //         "image/png",
    //         "image/pdf",
    //         "application/pdf",
    //       ].includes(value?.type)
    //     );
    //   }),
    pan_file: Yup.mixed()
      .required("PAN document is required")
      .test("fileFormat", "Unsupported file format", (value) => {
        return (
          value &&
          [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "image/pdf",
            "application/pdf",
          ].includes(value?.type)
        );
      }),
  });
  const fileName = (name, e) => {
    if (name === "gst") {
      setGstfile(e.target.files[0].name);
    } else if (name === "pancard") {
      setPanfile(e.target.files[0].name);
    }
  };

  const handleFile = (name, e) => {
    const regex = /^.*\.(jpg|JPG|jpeg|JPEG|pdf|PDF|png|PNG)$/;
    if (regex.test(e.target.files[0]?.name)) {
      fileName(name, e);
      const data = new FormData();
      data.append("filename", e.target.files[0]);
      dispatch(profileFileAction(data, name));
    }
  };

  const handleChange = (e) => {
    setOnCaptchaCheck(true);
  };

  function recaptchaLoaded() {}
  const submitForm = (value, resetForm) => {
    let user = {
      organization: value.organization,
      name: value.name,
      email: value.email,
      bankAccountNumber: value.bankAccountNumber,
      ifscCode: value.ifscCode,
      mobileNumber: value.mobileNumber,
      panNumber: value.panNumber,
      gstNumber: value.gstNumber,
      tan_num: value.tan_num,
      gst_file: gst,
      pan_file: pan,
    };
    // if(true){
    if (onCaptchaCheck) {
      dispatch(vendorRegisterAction({ details: user, resetForm }));
      setGstfile("Upload Gst Certificate");
      setPanfile("Upload PAN Card");
    } else {
      toast.error("Please verfiy that you are not a robot");
    }

    // dispatch(vendorRegisterAction(user));
    // setGstfile("Upload Gst Certificate");
    // setPanfile("Upload PAN Card");
  };

  const onMobileChange = (e, setFieldValue) => {
    const regx = /^[0-9]{0,10}$/;
    if (regx.test(e.target.value)) {
      const mobile = e.target.value;
      setFieldValue("mobileNumber", mobile, true);
    }
  };

  const onAccountNoChange = (e, setFieldValue) => {
    const regex = /^[0-9]{20}$/;
    if (regex.test(e.target.value)) {
      const account = e.target.value;
      setFieldValue("bankAccountNumber", account, true);
    }
  };

  const onIfscCodeChange = (e, setFieldValue) => {
    if (e.target.value.length <= 11) {
      setFieldValue("ifscCode", e.target.value, true);
    }
  };

  return (
    <>
      <div className="registerPage">
        <div className="leftside"></div>
        <div className="rightside">
          <Formik
            initialValues={initialvalue}
            validationSchema={vendorSchema}
            onSubmit={(values, { resetForm }) => {
              submitForm(values, resetForm);
            }}
          >
            {({ errors, touched, setFieldValue, values }) => {
              return (
                <Form>
                  <div className="row reg-rows">
                    <div className=" col regfield-col">
                      <label className="reg-label">
                        COMPANY NAME<span className="mandatory">*</span>
                      </label>
                      <Field
                        type="text"
                        className="reg-input"
                        name="organization"
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.organization && touched.organization ? (
                          <div>{errors.organization}</div>
                        ) : null}
                      </span>
                    </div>
                    <div className="col regfield-col">
                      <label className="reg-label">
                        NAME<span className="mandatory">*</span>
                      </label>
                      <Field type="text" className="reg-input" name="name" />
                      <span className="venusername">
                        {errors.name && touched.name ? (
                          <div>{errors.name}</div>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <div className="row reg-rows">
                    <div className="col regfield-col">
                      <label className="reg-label">
                        EMAIL ID<span className="mandatory">*</span>
                      </label>
                      <Field type="text" className="reg-input" name="email" />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.email && touched.email ? (
                          <div>{errors.email}</div>
                        ) : null}
                      </span>
                    </div>
                    <div className="col regfield-col">
                      <label className="reg-label">
                        CONTACT NUMBER<span className="mandatory">*</span>
                      </label>
                      <Field
                        type="text"
                        className="reg-input"
                        name="mobileNumber"
                        onChange={(e) => onMobileChange(e, setFieldValue)}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.mobileNumber && touched.mobileNumber ? (
                          <div>{errors.mobileNumber}</div>
                        ) : null}
                      </span>
                    </div>
                  </div>
                  <div className="row reg-rows">
                    <div className="col regfield-col">
                      <label className="reg-label">
                        Account No.<span className="mandatory">*</span>
                        <span className="accnosubtext">
                          (Account Where payment needs to be done)
                        </span>
                      </label>
                      <Field
                        type="text"
                        className="reg-input"
                        name="bankAccountNumber"
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.bankAccountNumber &&
                        touched.bankAccountNumber ? (
                          <div>{errors.bankAccountNumber}</div>
                        ) : null}
                      </span>
                    </div>
                    {/*new bank*/}
                    <div className="col regfield-col">
                      <label className="reg-label">
                        IFSC Code<span className="mandatory">*</span>
                      </label>
                      <Field
                        type="text"
                        className="reg-input"
                        name="ifscCode"
                        onChange={(e) => onIfscCodeChange(e, setFieldValue)}
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.ifscCode && touched.ifscCode ? (
                          <div>{errors.ifscCode}</div>
                        ) : null}
                      </span>
                    </div>
                    {/*new ifsc*/}
                  </div>
                  <div className="row reg-rows">
                    <div className="col regfield-col">
                      <label className="reg-label" style={{ marginTop: "7px" }}>
                        PAN NUMBER<span className="mandatory">*</span>
                      </label>
                      <Field
                        type="text"
                        className="reg-input"
                        name="panNumber"
                      />
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.panNumber && touched.panNumber ? (
                          <div>{errors.panNumber}</div>
                        ) : null}
                      </span>
                    </div>
                    <div className="col regfield-col">
                      <label className="reg-label">
                        PAN DOCUMENT <span className="mandatory">*</span>
                        <sub className="inv-label1">
                          (Upload Document in Jpeg , Jpg, Pdf, Png Format)
                        </sub>
                      </label>

                      <div className="filess custom-file">
                        <input
                          type="file"
                          name="pan_file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={(e) => [
                            handleFile("pancard", e),
                            setFieldValue("pan_file", e.target.files[0]),
                          ]}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          {panfile}
                        </label>
                      </div>
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.pan_file && touched.pan_file ? (
                          <div style={{ marginTop: "8px" }}>
                            {errors.pan_file}
                          </div>
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div className="row reg-rows">
                    <div className="col regfield-col">
                      <label className="reg-label" style={{ marginTop: "7px" }}>
                        GST NUMBER
                      </label>
                      <Field
                        type="text"
                        className="reg-input"
                        name="gstNumber"
                      />
                      <span className="error-text-vendor-form">
                        {errors.gstNumber && touched.gstNumber ? (
                          <div>{errors.gstNumber}</div>
                        ) : null}
                      </span>
                    </div>
                    <div className="col regfield-col">
                      <label className="reg-label">
                        GST DOCUMENT{" "}
                        <sub className="inv-label1">
                          (Upload Document in Jpeg , Jpg, Pdf, Png Format)
                        </sub>
                      </label>
                      <div className="filess custom-file">
                        <input
                          type="file"
                          name="gst_file"
                          className="custom-file-input"
                          id="customFile1"
                          onChange={(e) => [
                            handleFile(
                              "gst",
                              e,
                              setFieldValue("gst_file", e.target.files[0])
                            ),
                          ]}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile1"
                        >
                          {gstfile}
                        </label>
                      </div>
                      {/* <span style={{ color: "red", fontSize: "14px" }}>
                        {errors.gst_file && touched.gst_file ? (
                          <div style={{ marginTop: "8px" }}>
                            {errors.gst_file}
                          </div>
                        ) : null}
                      </span> */}
                    </div>
                  </div>

                  <div className="row reg-rows">
                    <div className="col col-6 regfield-col">
                      <label className="reg-label tan-num">TAN NUMBER</label>
                      <Field
                        type="text"
                        className="reg-input"
                        name="tan_num"
                        MaxLength="10"
                      />
                      <span className="error-text-vendor-form">
                        {errors.tan_num && touched.tan_num ? (
                          <div>{errors.tan_num}</div>
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div className="row reg-rows captcha-row venreg-iframe">
                    <ReCaptchaV2
                      className="venreg-iframe"
                      //  oldkey 6LdRNd8fAAAAAEUNlJML-ff2hy7nTqyLURZNTIfm
                      sitekey="6LexaksmAAAAAPnoQRPGg0Ia-nVkaF8ECvdXc-Lo"
                      // sitekey="6LdGmNklAAAAAGsCKwjYHbc8G9sLFVMzZpZEyDbz"
                      onChange={handleChange}
                      onloadCallback={recaptchaLoaded}
                    />
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {errors.recaptcha && touched.recaptcha ? (
                        <div>{errors.recaptcha}</div>
                      ) : null}
                    </span>
                  </div>
                  <div className="row regbtn-rows">
                    <button type="submit" className="regsbmtbtn">
                      SUBMIT
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default VendorRegisterForm;
